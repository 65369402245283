var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-menu-item",
    {
      attrs: {
        index: _vm.routerInfo.name,
        route: { parameters: _vm.routerInfo.parameters },
      },
    },
    [
      _c("i", { class: "el-icon-" + _vm.routerInfo.meta.icon }),
      _vm._v(" "),
      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v(_vm._s(_vm.routerInfo.meta.title)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }